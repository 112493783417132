import React from "react"
import { Image } from "@/Components/common/Image"
import { ICard, maskCardNumber } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import { useSearch } from "../../Widget/useSearch"

export const SidebarAccountCards = ({
  cards,
  fetching,
  widgetView = false,
}: {
  widgetView?: boolean
  cards: ICard[]
  fetching: boolean
}) => {
  const history = useHistory()
  let message = ""
  if (fetching) message = "Loading..."
  else if (cards?.length === 0) message = "No active cards yet."

  if (message !== "") return <div className="account-sidebar_cards-item">{message}</div>

  return (
    <>
      {cards?.map((card, key) => (
        <CardItem card={card} key={key} widgetView={widgetView} onClick={history.push} />
      ))}
    </>
  )
}

const CardItem = ({
  card,
  onClick,
  widgetView,
}: {
  card: ICard
  onClick: (path: string) => void
  widgetView: boolean
}) => {
  const { card_name, card_pan } = card

  const { pathWithQuery: cardsPath } = useSearch(`${widgetView ? "/widgets" : ""}/cards/${card.id}`)

  return (
    <div
      className="account-sidebar_cards-item"
      onClick={() => onClick(cardsPath)}
      style={{ cursor: "pointer" }}
    >
      <div className="account-sidebar_cards-name">
        <Image src="cars-img.svg" /> {card_name}
        <span>{maskCardNumber(card_pan)}</span>
      </div>
      {/* <div className="account-sidebar_cards-value">
        <strong>{parseFloat(balance).toFixed(2)}</strong>
        <span>{currency}</span>
      </div> */}
    </div>
  )
}
