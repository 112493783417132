import "./index.sass"
import { JustChips } from "./JustChip"
import { Image } from "@/Components/common/Image"

const Card = ({
  title,
  number,
  content,
}: {
  delay: string
  title: string
  number: number
  content: string
}) => {
  return (
    <div className={`step-cards__item step-cards__item_${number}`}>
      {number === 1 && <Image src="just-image.png" className="phone-register" />}
      <h1>
        <span>{number}</span> {title}
      </h1>
      <span>{content}</span>
      {number === 3 && <JustChips />}
    </div>
  )
}

export const AccessDenied = () => {
  return (
    <div className="three-steps">
      <div className="three-steps__header">
        <h1>
          JUST <span className="chip-fill">3 STEPS</span> <span>TO START</span>
        </h1>
        <div>
          <p>Unlock the power of seamless payments worldwide with our crypto card! </p> Order now
          and experience unmatched convenience! Contact our support team to get started and enjoy
          effortless transactions. Don’t miss out!
        </div>
      </div>
      <div className="step-cards">
        <Card
          delay="200"
          title="Open Free Account"
          content="Sign Up, submit your details, your ID or Passport"
          number={1}
        />
        <Card
          delay="400"
          title="Get a Card"
          content="Choose a debit card that suits your needs & style."
          number={2}
        />
        <Card
          delay="600"
          title="Top Up Your Card"
          content="Top up your card with BTC, ETH, LTC, USDT"
          number={3}
        />
      </div>
    </div>
  )
}
