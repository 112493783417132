import create from "zustand"
import cr from "crypto"
const api_key = "e6305530-e067-49f2-85f5-617cee153f94"
const secret = "e6405530-e067-49f2-85f5-617cee153f94"

const getSignature = (timestamp: string, payload: number) => {
  const hmac = cr.createHmac("sha256", secret)
  hmac.update(api_key + timestamp + `client_id=${payload}`)
  return hmac
}
export const sessionStore = create<{
  session_id: string | null | number
  loading: boolean
  fetchSession: (id: number) => void
}>((set) => ({
  session_id: null,
  loading: false,
  fetchSession: async (userID: number) => {
    set((old) => ({ ...old, loading: true }))
    const timestamp = Math.floor(Date.now() / 1000).toString()
    const signature = getSignature(timestamp, userID).digest("hex")
    const headers = new Headers()
    headers.append("X-SIGNATURE", signature)
    headers.append("X-API-KEY", "e6305530-e067-49f2-85f5-617cee153f94")
    headers.append("X-TIMESTAMP", timestamp)
    headers.append("Cookie", "PHPSESSID=debcc9b66d17ceb541bf0330cb62614a")
    const formdata = new FormData()
    formdata.append("action", "get_session")
    formdata.append("client_id", String(userID ?? ""))
    const data = await fetch("https://devpay.wizarbit.com/api/cards/api.htm", {
      method: "POST",
      headers,
      body: formdata,
    })
    const result = await data.json()
    console.log(">>>>>>>>>", result)
    if (result.success === false) {
      return set((old) => ({ session_id: 0, loading: false }))
    } else
      return set((old) => ({ ...old, session_id: (result.data as string) ?? "", loading: false }))
  },
}))
