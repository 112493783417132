import React, { useState } from "react"
import classnames from "classnames"
import DatePicker from "react-datepicker"
import { Controller, FormProvider, useWatch } from "react-hook-form"
import { useOrdersFilter } from "@usher/pe-client-front-end-lib"
import { IconChevronDown /*, IconClose*/, IconSearch } from "@/Images/Icons"
import Button from "@/Components/Button"
import "./index.sass"
import "react-datepicker/dist/react-datepicker.min.css"
import { Selector } from "@/Components/Selector"

function FilterSelect({ formMethods, options, name, label }) {
  return (
    <div className="TransactionsFilter__Select">
      <div className="TransactionsFilter__Label">{label}:</div>
      <div>
      <Controller
        control={formMethods.control}
        name={name}
        render={({ onChange, value, name }) => (
          <Selector
            options={options[name]}
            searchable 
            value={value}
            onChange={x => onChange(x, name)}
            />
        )}
      />
      </div>
    </div>
  )
}

export const TransactionsFilter = ({
  onFilter,
  type = "Buy",
  withoutExchangeTo = false,
  compact = false,
  widgetView = false,
}) => {
  const { options, /*onReset, */ onSubmit, formMethods } = useOrdersFilter({
    onFilter,
    type,
    compact,
  })
  const [filterOpen, setFilterOpen] = useState(false)
  const watchPeriod = useWatch({name: "period", control: formMethods.control})
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={classnames({
          "TransactionsFilter--Open": filterOpen,
          TransactionsFilter: !widgetView,
        })}
      >
        <div className="TransactionFilter__Head" onClick={() => setFilterOpen(!filterOpen)}>
          Search <IconChevronDown />
        </div>
        <div className="TransactionsFilter__Inner">
          <div className="TransactionsFilter__Items">
            <FilterSelect
              formMethods={formMethods}
              options={options}
              name="period"
              label="Period"
            />
            {watchPeriod.value !== "0" && (
              <div className="TransactionsFilter__Select" style={{flex: 1}}>
                <Controller
                  control={formMethods.control}
                  name="date"
                  render={({ onChange, value, name }) => (
                    <div className="d-flex flex-column">
                      <label style={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontFamily: "ChakraPetch-Regular",
                        color: "#9A9A9A",
                        marginRight: "9px",
                      }}>Select Period:</label>
                    <DatePicker
                      name={name}
                      selected={value[0]}
                      startDate={value[0]}
                      endDate={value[1]}
                      onChange={onChange}
                      maxDate={new Date()}
                      className="form-orders_item_date"
                      isClearable
                      selectsRange
                    />
                    </div>
                  )}
                />
              </div>
            )}
            {withoutExchangeTo !== true && (
              <>
                <FilterSelect
                  formMethods={formMethods}
                  options={options}
                  name="fiat"
                  label="Exchange from"
                />
                <FilterSelect
                  formMethods={formMethods}
                  options={options}
                  name="crypto"
                  label="Exchange to"
                />
              </>
            )}
            <FilterSelect
              formMethods={formMethods}
              options={options}
              name="status"
              label="Status"
            />
          </div>
          <Button
            icon={<div style={{marginRight: '8px'}}><IconSearch color="#000000" stroke="#000000" /></div>}
            // color="Blue"
            size="Medium"
            type="submit"
            style={{ marginLeft: "auto", border: '1px solid #000000', borderRadius: 0, textTransform: 'unset', paddingLeft: "20px", paddingRight: "20px", minWidth: 'unset' }}
          >
            Results
          </Button>
          {/*<Button icon={<IconClose/>} color="Dark" size="Medium" onClick={onReset}
                  className="TransactionsFilter__Reset"/>*/}
        </div>
      </form>
    </FormProvider>
  )
}
