import React from "react"
import { Link, useRouteMatch, useParams, useHistory } from "react-router-dom"
import { useStoreCardView, useTUCardStore } from "@usher/pe-client-front-end-lib"
import "./style.sass"
import { CardStatus } from "./CardStatus"
import { CardStatus as Status } from "@/gql/types"
import { isWidgetPage } from "@/utils"
import { useSearch } from "../../Widget/useSearch"

export const CardDetailsHeader = () => {
  const { url } = useRouteMatch()
  const isTransactions = url.indexOf("/transactions") > 0
  const navigate = useHistory()
  const setCardType = useTUCardStore((state) => state.setCardType)
  const setCard = useTUCardStore((state) => state.setCard)
  const { id } = useParams<{ id: string }>()
  const { cardNumber, balance, currency, status, type } = useStoreCardView((state) => ({
    cardNumber: state.cardNumber,
    balance: state.card.balance,
    currency: state.card.currency,
    status: state.card.status,
    type: state.card.type,
  }))
  const urlPrefix = isWidgetPage() ? "/widgets/cards" : "/cards"

  const { pathWithQuery: cardDetailsLink } = useSearch(`${urlPrefix}/${id}`)
  const { pathWithQuery: transactionsLink } = useSearch(`${urlPrefix}/${id}/transactions`)

  return (
    <>
      <div className="main-content_one-card_title card_details_header card_details_balance">
        <span>{cardNumber}</span>
        <div className="card_balance">
          <span>(Balance: &nbsp; </span>
          <span className={balance < 0 ? "text-danger" : "text-success"}>
            {`${balance.toFixed(2)} ${currency} `}
          </span>
          ){status.length > 0 && <CardStatus status={status} />}
        </div>
        {status === Status.Active.toUpperCase() && !isWidgetPage() && (
          <CardStatus
            status="Top-up Card"
            className="top-up"
            onClick={() => {
              setCard(id)
              setCardType(type === "1" ? "Virtual" : "Physical")
              navigate.push("/top-up-card/by-crypto")
            }}
          />
        )}
      </div>
      <div className="main-content_one-card_ul list">
        <li className={!isTransactions ? "active" : ""}>
          <Link to={cardDetailsLink}>Card details</Link>
        </li>
        <li className={isTransactions ? "active" : ""}>
          <Link to={transactionsLink}>Transactions</Link>
        </li>
      </div>
      {/*<div className="main-content_one-card_info">
        CARDS &nbsp;&middot;&nbsp; {cardNumber}
      </div>*/}
    </>
  )
}
