import { ReactNode } from "react"

const JustChip = ({ icon, text }: { icon: ReactNode; text: string }) => {
  return (
    <div
      className="just-chip"
      style={icon === "50+" ? { border: "2px dashed black", background: "transparent" } : {}}
    >
      {icon} {text}
    </div>
  )
}
export const JustChips = () => {
  return (
    <div className="just-chips">
      <div>
        <JustChip
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.3867" cy="15.9104" r="14" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.722656 15.9297C0.722656 7.36946 7.66243 0.429688 16.2227 0.429688C24.7829 0.429688 31.7227 7.36946 31.7227 15.9297C31.7227 24.4899 24.7829 31.4297 16.2227 31.4297C7.66243 31.4297 0.722656 24.4899 0.722656 15.9297ZM17.3697 5.24737C17.2393 5.06471 17.0672 4.91582 16.8677 4.81309C16.6682 4.71036 16.4471 4.65677 16.2227 4.65677C15.9983 4.65677 15.7771 4.71036 15.5776 4.81309C15.3781 4.91582 15.206 5.06471 15.0757 5.24737L8.0302 15.111C7.85965 15.3499 7.76797 15.6361 7.76797 15.9297C7.76797 16.2232 7.85965 16.5095 8.0302 16.7484L15.0757 26.612C15.206 26.7947 15.3781 26.9436 15.5776 27.0463C15.7771 27.149 15.9983 27.2026 16.2227 27.2026C16.4471 27.2026 16.6682 27.149 16.8677 27.0463C17.0672 26.9436 17.2393 26.7947 17.3697 26.612L24.4151 16.7484C24.5857 16.5095 24.6773 16.2232 24.6773 15.9297C24.6773 15.6361 24.5857 15.3499 24.4151 15.111L17.3697 5.24737ZM16.2227 17.2303L12.9719 15.9297L16.2227 14.6291L19.4734 15.9297L16.2227 17.2303ZM16.7454 20.0555L19.3269 19.0241L16.2227 23.3683L13.1184 19.0241L15.6999 20.0555C16.0355 20.1896 16.4098 20.1896 16.7454 20.0555ZM16.2227 8.48969L13.1184 12.8353L15.6999 11.8039C16.0355 11.6698 16.4098 11.6698 16.7454 11.8039L19.3269 12.8353L16.2227 8.48969Z"
                fill="#9E64FE"
              />
            </svg>
          }
          text="Ethereum"
        />
      </div>
      <div>
        <JustChip
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.1029 31.841C24.8388 31.841 31.9206 24.7592 31.9206 16.0233C31.9206 7.2874 24.8388 0.205566 16.1029 0.205566C7.36699 0.205566 0.285156 7.2874 0.285156 16.0233C0.285156 24.7592 7.36699 31.841 16.1029 31.841Z"
                fill="#9E64FE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.84691 8H22.1735C22.6446 8 23.0372 8.0786 23.5082 8.31441C24.0578 8.55022 24.3719 8.94323 24.5289 9.25764C24.5289 9.25764 24.5289 9.33625 24.6074 9.33625C24.843 9.72926 25 10.2009 25 10.7511C25 11.2227 24.9215 11.6943 24.6074 12.1659L16.8346 25.5284C16.6776 25.8428 16.3635 26 15.971 26C15.6569 26 15.3428 25.8428 15.1073 25.5284L7.4915 12.1659C7.33448 11.8515 7.02042 11.4585 7.02042 10.8297C6.94191 10.2795 7.09894 9.80786 7.33448 9.33625C7.57002 8.86463 7.96258 8.47162 8.51218 8.31441C8.98326 8 9.53285 8 9.84691 8ZM15.0288 9.88646H9.84691C9.53285 9.88646 9.37583 9.88646 9.29731 9.96507C9.14029 10.0437 9.06177 10.1223 8.98326 10.2795C8.90475 10.3581 8.90475 10.5153 8.90475 10.6725C8.90475 10.7511 8.98326 10.8297 9.14029 11.1441L15.0288 21.3624V9.88646ZM16.9131 9.88646V21.441L22.8801 11.1441C22.9587 10.9869 22.9587 10.8297 22.9587 10.6725C22.9587 10.5153 22.9587 10.3581 22.8801 10.2795C22.8016 10.2009 22.8016 10.1223 22.7231 10.1223L22.6446 10.0437C22.4876 9.96507 22.3305 9.96507 22.095 9.96507H16.9131V9.88646Z"
                fill="white"
              />
            </svg>
          }
          text="Toncoin"
        />
        <JustChip
          icon={
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.4681 31.7473C25.204 31.7473 32.2858 24.6654 32.2858 15.9295C32.2858 7.19365 25.204 0.111816 16.4681 0.111816C7.73223 0.111816 0.650391 7.19365 0.650391 15.9295C0.650391 24.6654 7.73223 31.7473 16.4681 31.7473Z"
                fill="#9E64FE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3685 17.2971V17.2951C18.2598 17.303 17.6992 17.3366 16.4486 17.3366C15.4501 17.3366 14.7472 17.307 14.5001 17.2951V17.2981C10.6564 17.129 7.78744 16.4597 7.78744 15.659C7.78744 14.8592 10.6564 14.1899 14.5001 14.0179V16.6318C14.7512 16.6495 15.4709 16.6921 16.4654 16.6921C17.6587 16.6921 18.2568 16.6426 18.3685 16.6327V14.0198C22.2043 14.1909 25.0663 14.8602 25.0663 15.659C25.0663 16.4597 22.2043 17.127 18.3685 17.2971ZM18.3685 13.748V11.4089H23.7208V7.84204H9.14776V11.4089H14.5001V13.747C10.1502 13.9467 6.87891 14.8088 6.87891 15.8409C6.87891 16.873 10.1502 17.734 14.5001 17.9347V25.4304H18.3685V17.9328C22.7115 17.7331 25.9748 16.872 25.9748 15.8409C25.9748 14.8097 22.7115 13.9487 18.3685 13.748Z"
                fill="white"
              />
            </svg>
          }
          text="Tether"
        />
      </div>
      <div>
        <JustChip
          icon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_127_4226)">
                <path
                  d="M31.9958 15.9452C31.9958 19.0736 31.0681 22.1318 29.33 24.733C27.592 27.3342 25.1216 29.3616 22.2313 30.5588C19.3409 31.756 16.1605 32.0693 13.0922 31.459C10.0239 30.8486 7.20541 29.3421 4.99326 27.13C2.78111 24.9178 1.27462 22.0994 0.664292 19.031C0.0539615 15.9627 0.367205 12.7823 1.56441 9.89198C2.76162 7.00167 4.78901 4.53128 7.39022 2.79321C9.99144 1.05513 13.0496 0.127441 16.1781 0.127441C20.3732 0.127441 24.3965 1.79395 27.3629 4.76034C30.3293 7.72674 31.9958 11.75 31.9958 15.9452Z"
                  fill="#9E64FE"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.57087 8.79601L13.3341 8.81511L13.3514 5.6797L15.2347 5.69551L15.2162 8.70189L16.7513 8.71092L16.7632 5.67358L18.6774 5.68275L18.6557 8.73905C18.6557 8.73905 21.7463 8.61947 23.0567 10.8148C24.3671 13.0102 22.4968 14.8344 21.8304 15.0598C21.8304 15.0598 24.7579 15.7918 24.6032 18.7833C24.4486 21.7749 21.8798 22.9766 18.8749 23.0886L18.8809 26.2506L16.9667 26.2414L16.988 23.1213L15.4678 23.1069L15.452 26.2478L13.552 26.2364L13.5729 23.109L9.70809 23.0823L10.0879 20.8241C10.0879 20.8241 11.179 20.8351 11.5884 20.8259C11.9977 20.8166 12.1402 20.351 12.1436 19.922C12.147 19.4929 12.0395 12.4254 12.0505 11.8383C12.0614 11.2511 11.8868 10.8406 11.1945 10.8423C10.5022 10.8441 9.55138 10.8126 9.55138 10.8126L9.57087 8.79601ZM15.4244 16.6573L15.4663 20.7836C15.4663 20.7836 20.7514 21.2598 20.7256 18.6456C20.6998 16.0314 15.4244 16.6573 15.4244 16.6573ZM15.3905 14.7038L15.4155 10.9063C15.4155 10.9063 19.7434 10.5617 19.7522 12.669C19.7609 14.7762 17.1256 14.6589 15.3905 14.7038Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_127_4226">
                  <rect
                    width="31.6354"
                    height="31.6354"
                    fill="white"
                    transform="translate(0.360352 0.127441)"
                  />
                </clipPath>
              </defs>
            </svg>
          }
          text="Bitcoin"
        />
        <JustChip
          icon={
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_127_4229)">
                <path
                  d="M16.6329 0.127441C12.4532 0.139634 8.44921 1.81012 5.50019 4.77207C2.55117 7.73402 0.898217 11.7453 0.904314 15.925C0.91041 20.1047 2.57506 24.1111 5.5327 27.0644C8.49035 30.0177 12.4992 31.6765 16.6789 31.6765C20.8586 31.6765 24.8674 30.0177 27.8251 27.0644C30.7827 24.1111 32.4473 20.1047 32.4534 15.925C32.4595 11.7453 30.8066 7.73402 27.8576 4.77207C24.9086 1.81012 20.9046 0.139634 16.7249 0.127441L16.6329 0.127441Z"
                  fill="#9E64FE"
                />
                <path
                  d="M16.9205 16.299L15.5271 20.9781H22.9413C22.9903 20.9757 23.0393 20.9835 23.0851 21.0011C23.1309 21.0183 23.1729 21.0442 23.2088 21.0774C23.2444 21.1114 23.2732 21.1519 23.2936 21.1967C23.3152 21.2416 23.327 21.2907 23.3281 21.3405V21.4627L22.6825 23.6873C22.6548 23.7928 22.5913 23.8853 22.5027 23.949C22.415 24.0133 22.308 24.0458 22.1993 24.041H10.8537L12.7547 17.5701L10.6279 18.2143L11.1111 16.7318L13.2379 16.0861L15.9139 6.98376C15.9429 6.8789 16.0068 6.78707 16.0951 6.72349C16.1824 6.65915 16.2887 6.62575 16.3971 6.62858H19.273C19.3221 6.62733 19.3709 6.63563 19.4168 6.65303C19.4632 6.66987 19.5057 6.69578 19.5419 6.72924C19.5782 6.76267 19.6071 6.80333 19.6268 6.8486C19.6474 6.8938 19.6586 6.94272 19.6599 6.99239V7.11606L17.4037 14.7877L19.5319 14.142L19.0775 15.6864L16.9205 16.299Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_127_4229">
                  <rect
                    width="31.6354"
                    height="31.6354"
                    fill="white"
                    transform="translate(0.81543 0.127441)"
                  />
                </clipPath>
              </defs>
            </svg>
          }
          text="Litecoin"
        />
      </div>
      <div>
        <JustChip icon="50+" text="available assets" />
      </div>
    </div>
  )
}
