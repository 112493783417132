import QueryString from "qs"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useSearch = <T extends Record<string, string>>(
  path?: string
): { query: T; pathWithQuery: string } => {
  const { search } = useLocation()

  const query = useMemo(() => QueryString.parse(search?.replace(/\?/, "")) as T, [search])

  const pathWithQuery = useMemo(() => {
    if (path != null) {
      return [path, search].join("")
    }

    return ""
  }, [search, path])

  return { query, pathWithQuery }
}
