import { useEffect } from "react"
import { sessionStore } from "./store"
import { useLocation, useRouteMatch } from "react-router-dom"
import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"

export const useWidget = () => {
  const { id } = useStoreClient(selectUser)
  const { pathname } = useLocation()
  const fetchSession = sessionStore((state) => state.fetchSession)
  useEffect(() => {
    fetchSession(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pathname])
  const path = useRouteMatch().path
  return { path }
}
