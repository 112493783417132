import { Button } from "@/Components/Control/Button"
import { useEffect, useState } from "react"
import { AssetLinkBox } from "./AssetLinkBox"
import "./style.css"
import { ApiData } from "@usher/pe-client-front-end-lib"
import { Currencies } from "@/types"
import { Msg } from "@/Components/Msg"

export const HomeAssets = () => {
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 1126 && showAll) setShowAll(false)
    }
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [showAll])

  const [filteredRates, setFilteredRates] = useState([])

  useEffect(() => {
    ApiData.getCurrenciesExchange().then((data) => {
      const rates = Object.entries(data?.crypto_prices ?? {}).map(([symbol, rate]) => ({
        symbol,
        rate,
      }))

      setFilteredRates(rates)
    })
  }, [])

  const isLess = window.innerWidth < 1120

  return (
    <section className="home_section assets">
      <div className="container">
        <div className="assets__box">
          <div className="assets__info">
            <div className="assets__top">
              <h2 className="h2 assets__h2">
                <Msg id="home1" />
              </h2>
              <p className="assets__describe">
                <Msg id="home2" />
              </p>
              {/* <img src="img/assets-image.png" alt="" className="assets__image" /> */}
            </div>
          </div>
          <div className={`assets__info-middle assets__info-top`}>
            <AssetLinkBox rates={filteredRates} currency={Currencies.BTC} />
            <AssetLinkBox rates={filteredRates} currency={Currencies.ETH} />
            <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_ERC20} />
          </div>
          {showAll && (
            <>
              <div className={`assets__info-middle${showAll && isLess ? " showAll" : ""}`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_TRC20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_ERC20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.LTC} />
              </div>
              <div className={`assets__info-middle${showAll && isLess ? " showAll" : ""}`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_BEP20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.DASH} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.ICP} />
              </div>
            </>
          )}
          <div
            className="assets__info-bottom"
            style={{
              justifyContent: showAll && !isLess ? "flex-end" : "unset",
              marginRight: showAll && !isLess ? -7 : 0,
            }}
          >
            <div className="assets__info-bottom-info">
              <p className="assets__info-bottom-text">
                <Msg id="home3" />
              </p>
              <Button
                onClick={() => {
                  setShowAll(!showAll)
                }}
                minWidth={242}
              >
                <Msg id={`${showAll ? "hide" : "see"}_assets`} />
              </Button>
            </div>
            <div className={`assets__info-bottom-cards${showAll && isLess ? " showAll" : ""}`}>
              {!showAll ? (
                <>
                  <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_TRC20} />
                  <AssetLinkBox rates={filteredRates} currency={Currencies.DASH} />
                </>
              ) : (
                <>
                  <AssetLinkBox rates={filteredRates} currency={Currencies.BNB} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
