import plus from "@/Images/cards_images/plus.svg"
import { SidebarAccountCards } from "./AccountCards"
import { Link } from "react-router-dom"
import { useActiveCards } from "@usher/pe-client-front-end-lib"
import { useSearch } from "../Widget/useSearch"

export const WidgetMain = () => {
  const { activeCards, loading, isCardActive, isAllExist } = useActiveCards()
  const balance = Number(activeCards?.[0]?.balance ?? "0")

  const { pathWithQuery: expensePath } = useSearch("/client/cards/order-card/expense")

  return (
    <>
      {isCardActive && (
        <div className="account-sidebar_cards">
          <div
            className="table-orders_title"
            style={{
              marginBottom: 8,
              display: "flex",
              fontSize: "12px",
              lineHeight: "16px",
              fontFamily: "ChakraPetch-SemiBold",
              color: "#777e8a",
            }}
          >
            Card Balance:&nbsp;&nbsp;
            <div style={{ color: balance <= 0 ? "#ef4444" : "#22c55e" }}>
              {` ${balance.toFixed(2)} EUR`}
            </div>
          </div>
          {!loading && !isAllExist && (
            <Link className="account-sidebar_cards-add" to={expensePath}>
              <img src={plus} alt="" />
            </Link>
          )}
          <SidebarAccountCards widgetView cards={activeCards} fetching={loading} />
        </div>
      )}
    </>
  )
}
