import plus from "@/Images/cards_images/plus.svg"
import { SidebarAccountCards } from "./AccountCards"
import { Link } from "react-router-dom"
import { useActiveCards } from "@usher/pe-client-front-end-lib"
import { useState } from "react"
import Button from "@/Components/Button"

export const DashboardInfoBar = ({ isMainPanel }: { isMainPanel?: boolean }) => {
  const { activeCards, loading, isCardActive, isAllExist, cards } = useActiveCards()
  const [isOpen, setIsOpen] = useState(false)
  const balance = Number(activeCards?.[0]?.balance ?? "0")
  const isWaitExist = cards.some((card) => card.status.toLowerCase().includes("wait"))
  return (
    <>
      <div
        onClick={() => setIsOpen(false)}
        className={`right-sidebar_bg${isOpen ? " right-sidebar_bg--show" : ""}`}
      />

      <div
        className={`btn-account-sidebar${isOpen ? " is-active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div className={`main-content_sidebar account-sidebar ${isOpen ? "active" : ""}`}>
        {!loading && (
          <div className="account-sidebar_btn">
            <Link
              to="/exchange/transaction-details"
              className="btn-reset btn-sq btn-long btn-blue no-decorate"
              style={{ width: isCardActive ? "auto" : "100%" }}
            >
              Buy crypto
            </Link>
            {isCardActive && (
              <Link
                to={
                  activeCards?.length === 0 && !isWaitExist
                    ? "/client/cards/order-card/expense"
                    : "/top-up-card/by-crypto"
                }
                className="btn-reset btn-sq btn-long btn-trpr input-brdr no-decorate"
              >
                {activeCards?.length === 0 && !isWaitExist ? "Create Card" : "Top-up card"}
              </Link>
            )}
            {isMainPanel && (
              <Link
                to="/client"
                className="btn-reset btn-sq btn-long btn-trpr input-brdr no-decorate"
              >
                Go To Dashboard
              </Link>
            )}
          </div>
        )}
        {isCardActive && (
          <div className="account-sidebar_cards">
            <div
              className="table-orders_title"
              style={{ marginBottom: 8, display: "flex", fontSize: 14 }}
            >
              Card Balance:&nbsp;&nbsp;
              <div className={balance < 0 ? "text-danger" : balance > 0 ? "text-success" : ""}>
                {` ${balance.toFixed(2)}  EUR`}
              </div>
            </div>
            {!loading && !isAllExist && (
              <Link className="account-sidebar_cards-add" to="/client/cards/order-card/expense">
                <img src={plus} alt="" />
              </Link>
            )}
            <SidebarAccountCards cards={activeCards} fetching={loading} />
          </div>
        )}

        <div className="account-sidebar_footer for-telegram">
          <div className="account-sidebar_footer-title">Instant Card Top-Ups via Telegram Bot!</div>
          <div className="account-sidebar_footer-desc">
            Just use your credit card. Simply chat with our bot, specify the desired amount and
            cryptocurrency, and voila! Your card will be topped up instantly, ready for you to use
            for all your transactions.
          </div>
          <div style={{ textAlign: "right", width: "100%", marginTop: 20 }}>
            <Button
              className="ToolboxesBuy__Button"
              color="White"
              onClick={() => window.open("https://t.me/wizarbit_topupbot")}
            >
              Try It Now!
            </Button>
          </div>
        </div>

        <div className="account-sidebar_footer">
          <div className="account-sidebar_footer-title">Join our Referral Program</div>
          <div className="account-sidebar_footer-desc">Start earning money by inviting friends</div>
          <Link
            to="/affiliate-program"
            className="account-sidebar_footer-btn btn-reset btn-sq btn-white no-decorate input-brdr"
          >
            Join us
          </Link>
        </div>
        <div className="account-sidebar_footer">
          <div className="account-sidebar_footer-title">We’re here to help you!</div>
          <div className="account-sidebar_footer-desc">
            Ask any question or submit a ticket with an issue or manage requests. Our support team
            will get back to you by email.
          </div>
          <Link
            to="/faq"
            className="account-sidebar_footer-btn btn-reset btn-sq btn-white no-decorate input-brdr"
          >
            Get Support Now
          </Link>
        </div>
      </div>
    </>
  )
}
