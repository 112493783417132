import { Route, Switch, useRouteMatch } from "react-router-dom"
import { CardsList, WidgetView } from "./List"
import { CardsCreate } from "./Create"
import { CardsView } from "./Details"

export const CardsPage = () => {
  const path = useRouteMatch().path
  return (
    <Switch>
      <Route exact path={`${path}/`} component={CardsList} />
      <Route path={`${path}/order-card`} component={CardsCreate} />
      <Route path={`${path}/:id`} component={CardsView} />
    </Switch>
  )
}
export const CardsPageWidget = () => {
  const path = useRouteMatch().path

  return (
    <Switch>
      <Route exact path={`${path}/`} component={WidgetView} />
      <Route path={`${path}/order-card`} render={() => <CardsCreate isWidget />} />
      <Route path={`${path}/:id`} component={CardsView} />
    </Switch>
  )
}
