import React from "react"
// import { Card } from "@/gql/types"
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import { ICard, maskCardNumber } from "@usher/pe-client-front-end-lib"
import { useSearch } from "../../Widget/useSearch"

export const CardListItem = ({ card }: { card: ICard }) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { id, card_pan, card_name, expiry, card_type, status, card_holder } = card
  const isWaitStatus = card.status.toLowerCase().includes("wait")

  const { pathWithQuery: itemPath } = useSearch(`${path}${id}`)
  return (
    <div
      onClick={() => {
        if (!isWaitStatus) history.push(itemPath)
      }}
      className="table-orders_cards-item"
      style={{ cursor: "pointer" }}
    >
      <div className="table-orders_cards-filter_title">
        <Link to={itemPath}>{card_pan.length > 0 ? maskCardNumber(card_pan) : "********"}</Link>
      </div>
      <div className="table-orders_cards-filter_name">{card_holder ?? "N/A"}</div>
      <div className="table-orders_cards-filter_nickname">{card_name ?? "N/A"}</div>
      <div className="table-orders_cards-filter_date">{expiry}</div>
      <div className="table-orders_cards-filter_type">
        {card_type === "1" ? "Virtual Card" : "Physical Card"}
      </div>
      <div
        className={`table-orders_cards-filter_statust card-status-${status
          .toLowerCase()
          .replace(" ", "_")}`}
      >
        {status.replace("_", " ")}
      </div>
    </div>
  )
}
